<template lang="">
  <div>
    <h4 class="my-4">Varlıklar</h4>

    <div class="row">
      <div class="col-md-4 col-12">
        <router-link to="/properties/find/detail/1/">
        <b-card img-top tag="article" class="dveb-gray-card" no-body>
          <b-card-img
            src="https://bankamdan.propertize.com.tr/shared/album/1.jpg_1684929751.jpg"
            alt="Image"
            class=""
          ></b-card-img>

          <div class="row p-4">
            <div class="form-group w-100">
                   <p class="text-primary">Karadeniz Apartmanı</p>
            </div>
            <div class="d-flex">
              <div class="flex-grow-1 ">
                <p>Sahiplik Yüzdesi:</p>
                <p>Kazanç Oranı:</p>
              </div>
              <div class="flex-grow-1  ml-4">
                <p>50%</p>
                <p>5%</p>
              </div>
            </div>
          </div>
        </b-card>
    </router-link>

      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang=""></style>
